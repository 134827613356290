<template>
  <div class="article-notes">
    <el-container>
      <el-header class="head-search">
        <el-row :gutter="24" style="width: 100%; text-align: right">
          <el-col :span="4" style="text-align: left">
            <el-button
              color="#836FFF"
              style="color: white"
              @click="this.$router.push({ name: 'WriteNotes' })"
            >
              <icon icon-name="EditPen" icon-size="20" icon-title="编 写"></icon>
            </el-button>
          </el-col>
          <el-col :span="6">
            <el-input
              clearable
              show-word-limit
              maxlength="100"
              :prefix-icon="Search"
              v-model="search.keyword"
              placeholder="请输入关键字进行搜索！"
              @input="searchPage"
            />
          </el-col>
          <el-col :span="5">
            <el-cascader
              v-model="catalogSelect"
              style="width: 100%"
              filterable
              clearable
              :options="catalogOptions"
              placeholder="请选择目录进行搜索！"
              :props="{
                value: 'serial',
                label: 'title',
                checkStrictly: 'true',
                expandTrigger: 'hover'
              }"
              @change="searchPage"
            >
              <template #default="{ node, data }">
                <span>{{ data.title }}</span>
                <span v-if="!node.isLeaf">({{ data.children.length }})</span>
              </template>
            </el-cascader>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="search.tags"
              style="width: 100%"
              multiple
              filterable
              clearable
              collapse-tags-tooltip
              collapse-tags
              :multiple-limit="3"
              placeholder="请选择标签"
              @change="searchPage"
            >
              <el-option
                v-for="item in tagOptions"
                :key="item.serial"
                :label="item.tagName"
                :value="item.serial"
              />
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-select
              v-model="search.flag"
              style="width: 100%"
              clearable
              placeholder="是否公开"
              @change="searchPage"
            >
              <el-option key="YES" label="公开" value="YES" />
              <el-option key="NO" label="私有" value="NO" />
            </el-select>
          </el-col>
          <el-col :span="2" style="text-align: left; min-width: 110px">
            <el-button type="primary" color="#FF8C00" @click="resetSearch">
              <icon icon-name="Refresh" icon-size="20" icon-title="重 置"></icon>
            </el-button>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-table :data="tableData" :highlight-current-row="true">
          <el-table-column
            prop="title"
            :show-overflow-tooltip="true"
            label="标 题"
            header-align="center"
          >
            <template #default="scope">
              <el-icon :color="scope.row.topFlag === 'YES' ? '#DC143C' : ''">
                <component :is="scope.row.topFlag === 'YES' ? Flag : Notebook"></component>
              </el-icon>
              <span
                :style="
                  scope.row.topFlag === 'YES'
                    ? 'margin-left: 10px;color: #fe9600;user-select: text;'
                    : 'margin-left: 10px;user-select: text;'
                "
              >
                {{ scope.row.title }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="catalogName" label="所属目录" width="200" align="center" />
          <el-table-column prop="isOpen" label="是否公开" width="150" align="center">
            <template #default="scope">
              <el-tag v-if="scope.row.openFlag === 'YES'" color="#87CEFA" style="color: white">
                公开
              </el-tag>
              <el-tag type="danger" color="#FFC0CB" style="color: white" v-else>私有</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="tags" label="标 签" width="300" align="center">
            <template #default="scope">
              <el-tag
                v-for="(tag, index) in scope.row.tags"
                :key="index"
                :type="tagType[index]"
                style="margin: 2px"
              >
                {{ tag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="300" label="操 作" align="center">
            <template #default="scope">
              <el-button
                size="small"
                color="#00CED1"
                style="color: white"
                @click="queryContent(scope.row.serial)"
              >
                <icon icon-name="Compass" icon-size="20" icon-title="内 容"></icon>
              </el-button>
              <el-button size="small" type="warning">
                <icon
                  icon-name="Edit"
                  icon-size="20"
                  icon-title="编 辑"
                  @click="showInfoDrawer(scope.row.serial)"
                ></icon>
              </el-button>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                :icon="InfoFilled"
                icon-color="red"
                title="确定删除选项?"
                @confirm="deleteNotes(scope.row.serial)"
              >
                <template #reference>
                  <el-button size="small" type="danger">
                    <icon icon-name="Delete" icon-size="20" icon-title="删 除"></icon>
                  </el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer>
        <page
          @changePage="queryPage"
          :pageSize="page.size"
          :currentPage="page.current"
          :total="page.total"
        ></page>
      </el-footer>
    </el-container>
    <app-notes-info ref="infoDrawer" @updateInfo="updateInfo"></app-notes-info>
  </div>
</template>

<script>
import {
  catalogTree,
  deleteNotes,
  infoNotes,
  notesPage,
  tags,
  updateInfoNotes
} from '@/api/ArticleApi'
import icon from '@/components/icon'
import page from '@/components/page'
import NotesInfoDrawer from '@/views/article/components/NotesInfoDrawer'
import { Flag, InfoFilled, Notebook, Search } from '@element-plus/icons-vue'

export default {
  name: 'ArticleNotes',
  components: {
    'app-notes-info': NotesInfoDrawer,
    icon,
    page
  },
  setup() {
    return {
      Search,
      InfoFilled,
      Flag,
      Notebook
    }
  },
  data() {
    return {
      search: {
        keyword: '',
        catalog: '',
        tags: [],
        flag: null,
        currentPage: ''
      },
      page: {
        size: 13,
        current: 1,
        total: 0
      },
      catalogSelect: [],
      catalogOptions: [],
      tagOptions: [],
      tableData: [],
      tagType: ['success', 'warning', 'danger']
    }
  },
  methods: {
    searchPage() {
      if (this.catalogSelect) {
        this.search.catalog = this.catalogSelect[this.catalogSelect.length - 1]
      } else {
        this.search.catalog = ''
      }
      this.queryPage()
    },
    resetSearch() {
      this.search.keyword = ''
      this.search.catalog = ''
      this.search.tags = []
      this.search.flag = null
      this.catalogSelect = []
      this.queryPage()
    },
    initCatalogOptions() {
      catalogTree().then((res) => {
        if (res.code === 200) {
          this.catalogOptions = res.data
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
      tags().then((res) => {
        if (res.code === 200) {
          this.tagOptions = res.data
        }
      })
    },
    queryPage(current) {
      notesPage({
        title: this.search.keyword,
        catalog: this.search.catalog,
        flag: this.search.flag,
        tags: this.search.tags.join(),
        pageNum: typeof current !== 'undefined' ? current : 1,
        pageSize: this.page.size
      }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.rows
          this.page.size = res.data.pageSize
          this.page.current = res.data.currentPage
          this.page.total = res.data.total
          this.search.currentPage = res.data.currentPage
          this.$store.dispatch('home/syncNotesSearch', JSON.stringify(this.search))
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    queryContent(serial) {
      this.$router.push({ name: 'PreviewNotes', query: { serial: serial } })
    },
    deleteNotes(serial) {
      deleteNotes({
        serial: serial
      }).then((res) => {
        if (res.code === 200) {
          this.queryPage()
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    showInfoDrawer(serial) {
      infoNotes({
        serial: serial
      }).then((res) => {
        if (res.code === 200) {
          this.$refs.infoDrawer.fillInfo({
            serial: res.data.serial,
            title: res.data.title,
            prefixCatalog: res.data.catalog,
            cover: res.data.cover,
            coverUrl: res.data.coverUrl,
            tags: res.data.tags,
            intro: res.data.intro,
            isComment: res.data.commentFlag,
            isOpen: res.data.openFlag,
            isTop: res.data.topFlag
          })
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    updateInfo(info) {
      updateInfoNotes({
        serial: info.serial,
        title: info.title,
        catalog: info.prefixCatalog,
        cover: info.cover,
        tags: info.tags,
        intro: info.intro,
        commentFlag: info.isComment,
        openFlag: info.isOpen,
        topFlag: info.isTop
      }).then((res) => {
        if (res.code === 200) {
          this.queryPage()
          this.$refs.infoDrawer.closeDrawer()
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    }
  },
  mounted() {
    const searchParam = this.$store.getters['home/getNotesSearch']
    if (searchParam != null) {
      const param = JSON.parse(searchParam)
      this.search.keyword = param.keyword
      this.search.catalog = param.catalog
      this.search.flag = param.flag
      this.search.tags = param.tags
      this.catalogSelect = param.catalog
      this.queryPage(param.currentPage)
    } else {
      this.queryPage()
    }
    this.initCatalogOptions()
  }
}
</script>

<style scoped>
.article-notes {
  width: 100%;
  min-width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 5px;
}

.head-search {
  width: 100%;
  display: flex;
  align-items: center;
}

.article-notes >>> .el-main {
  min-height: 690px;
}
</style>
